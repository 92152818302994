<template>
    <v-data-table
        :class="{ 'edit-data-table': isPointer, 'elevation-1': true }"
        :height="tableHeight"
        :items-per-page="itemsPerPage"
        :footer-props="footerProps"
        :headers="headers"
        :items="items"
        :options.sync="optionsLocal"
        :server-items-length="itemTotal"
        :custom-sort="customSort"
        :loading="isLoading"
        item-key="id"
        :show-expand="showExpand"
        single-expand
        :hide-default-footer="hideDefaultFooter"
        fixed-header
        @click:row="$emit('goToEditPage')">
        <template v-for="header in headers" v-slot:[getSlotName(header.value)]="{ item }">
            <slot name="tableCell" :header="header" :item="item" />
        </template>
        <template v-for="header in headers" v-slot:item.action="{ item }">
            <slot
                v-if="canRead(claimName)"
                name="tableActions"
                :header="header"
                :item="item" />
        </template>
        <template v-slot:expanded-item="{ item }">
            <td class="pa-2" :colspan="headers.length + 1">
                <slot name="expand" :item="item" />
            </td>
        </template>
    </v-data-table>
</template>

<script>
export default {
    props: {
        getSlotName: {
            type: Function,
            default: null,
        },
        tableHeight: {
            type: Number,
            default: null,
        },
        itemsPerPage: {
            type: Number,
            default: null,
        },
        customSort: {
            type: Function,
            default: null,
        },
        footerProps: {
            type: Object,
            default: null,
        },
        headers: {
            type: Array,
            default: () => [],
        },
        items: {
            type: Array,
            default: () => [],
        },
        footerProp: {
            type: Array,
            default: () => [],
        },
        options: {
            type: Object,
            default: null,
        },
        itemTotal: {
            type: Number,
            default: null,
        },
        showExpand: {
            type: Boolean,
            default: false,
        },
        hasEditPage: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        hideDefaultFooter: {
            type: Boolean,
            default: false,
        },
        claimName: {
            type: String,
            default: null,
        },
    },

    computed: {
        optionsLocal: {
            get() {
                return this.options;
            },
            set(options) {
                this.$emit("update:options", options);
            },
        },
        isPointer() {
            return this.hasEditPage && !this.showExpand && this.items.length > 0;
        },
    },
};
</script>
<style>
    .edit-data-table tbody tr {
        cursor: pointer;
    }
</style>
